<template>
  <div>
    <div
      class="message text-center mt-5 m-auto"
    >
      <div class="mb-4 fw-bold fs-4">
        {{ $tcust('ForgotPasswordComplete.checkMail') }}
      </div>
      <div class="gradient custom-box">
        <div class="bg-box px-3 py-2 fs-5">
          {{ $tcust('ForgotPasswordComplete.confirmMail') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message {
  width: 400px;
  max-width: 90vw;
}
</style>
